<template>
  <div>
    <div class="wrap order-wrapper">
      <div class="main-container">
        <el-header
          class="header-container"
          style="height: auto; margin-bottom: 20px"
        >
          <div class="sports">
            <el-form ref="formTop" :model="formTop">
              <el-form-item
                prop="sportsProjectName"
                label="运动项目："
                label-width="100px"
              >
                <el-col :span="24">
                  <el-row>
                    <el-col :span="24">
                      <el-radio-group
                        class="venue-radio-wrapper"
                        @change="onChangeCode($event)"
                        v-model="sportRadio"
                        :max="1"
                      >
                        <el-radio-button
                          class="radio"
                          v-for="item in sportList"
                          :key="item.sportsProjectCode"
                          :label="item.sportsProjectCode"
                          :value="item"
                          >{{ item.sportsProjectName }}</el-radio-button
                        >
                      </el-radio-group>
                    </el-col>
                  </el-row>
                </el-col>
              </el-form-item>
            </el-form>
          </div>
          <div class="order-date">
            <el-container
              class="day-container"
              :class="{ 'chose-day': index === choseDay }"
              direction="vertical"
              v-for="(item, index) in dayList"
              :key="index"
              @click.native="getData(item, index)"
            >
              <el-container class="title">{{ item.title }}</el-container>
              <el-container class="content">{{
                item.content.substr(5, 5)
              }}</el-container>
            </el-container>
          </div>
          <!-- <el-container class="bto">
          <el-button>可选</el-button>
          <el-button type="success">已选</el-button>
          <el-button type="danger">已预定</el-button>
          <el-button type="info">已锁定</el-button>
        </el-container> -->
        </el-header>
        <div class="table-container-wrapper order-table" style="padding: 12px">
          <div class="table-order-tip">
            <span class="tip-wrapper"
              ><span class="tip-optional tip"></span>可选</span
            >
            <span class="tip-wrapper"
              ><span class="tip-selected tip"></span>已选</span
            >
            <span class="tip-wrapper"
              ><span class="tip-schedule tip"></span>已预定</span
            >
            <span class="tip-wrapper"
              ><span class="tip-coach tip"></span>教练预定</span
            >
            <span class="tip-wrapper"
              ><span class="tip-locked tip"></span>已锁定</span
            >
          </div>
          <el-table
            :class="[tableData.length > 0 ? 'table-data' : 'table-no-data']"
            :data="tableData"
            :height="tableHeight - 80"
            border
            @selection-change="handleSelectionChange"
          >
            <el-table-column prop="time" align="center" label="" width="110">
              <template slot-scope="scope">
                <span
                  v-if="scope.row.isVisible === '1'"
                  style="color: rgba(36, 45, 65, 1); font-size: 14px"
                  >{{ scope.row.time }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              v-for="(item, index) in tableHeaderData"
              :key="`header_${index}`"
              :prop="`timeSlotDetailList[${index}].customerName`"
              align="center"
              :label="item.spaceName"
              :render-header="renderHeader"
            >
              <template slot-scope="scope">
                <!-- <div v-if="scope.row.isVisible==='1'" style="min-height:34px;line-height:14px;font-size:12px;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:14px;color:color:rgba(36,45,65,1);" :class="{infoClass : scope.row.timeScheduleDataList[index].reservateStatus === '3', dangerClass : scope.row.timeScheduleDataList[index].reservateStatus === '2', succeClass : scope.row.timeScheduleDataList[index].reservateStatus === '4'}" @click="changeSelect(scope.row.timeScheduleDataList[index], scope.row,item)"> -->
                <el-tooltip
                  placement="top"
                  :disabled="!scope.row.timeScheduleDataList[index].orderId"
                >
                  <div slot="content">
                    客户：{{ scope.row.timeScheduleDataList[index].customerName
                    }}<br />
                    手机号：{{
                      scope.row.timeScheduleDataList[index].customerPhone
                    }}<br />
                    <!-- 用户备注：{{scope.row.timeScheduleDataList[index].customerRemark}}<br/> -->
                    商户备注：{{
                      scope.row.timeScheduleDataList[index].businessRemark
                    }}<br />
                    订单号：{{ scope.row.timeScheduleDataList[index].orderNo
                    }}<br />
                    订单金额：{{ scope.row.timeScheduleDataList[index].price
                    }}<br />
                    支付金额：{{
                      scope.row.timeScheduleDataList[index].actualPayPrice
                    }}<br />
                  </div>
                  <div
                    v-if="scope.row.isVisible === '1'"
                    style="min-height:34px;line-height:14px;font-size:12px;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:14px;color:color:rgba(36,45,65,1);"
                    :class="{
                      infoClass:
                        scope.row.timeScheduleDataList[index]
                          .reservateStatus === '3',
                      dangerClass:
                        scope.row.timeScheduleDataList[index]
                          .reservateStatus === '2' &&
                        scope.row.timeScheduleDataList[index]
                          .reservateOrderType === '1',
                      coachClass:
                        scope.row.timeScheduleDataList[index]
                          .reservateStatus === '2' &&
                        scope.row.timeScheduleDataList[index]
                          .reservateOrderType === '2',
                      succeClass:
                        scope.row.timeScheduleDataList[index]
                          .reservateStatus === '4',
                    }"
                    @click="
                      changeSelect(
                        scope.row.timeScheduleDataList[index],
                        scope.row,
                        item
                      )
                    "
                  >
                    <div style="font-size: 12px">
                      {{ scope.row.timeScheduleDataList[index].customerName }}
                    </div>
                    <div style="font-size: 12px">
                      {{ scope.row.timeScheduleDataList[index].customerPhone }}
                    </div>
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="order-container">
        <el-form ref="form" :model="form" :rules="rules" @submit.native.prevent>
          <div class="note-container">
            <div class="coach-wrapper">
              <p class="note-text">教练退订</p>
              <el-form-item prop="orderNo">
                <el-input
                  style="border-radius: 16px"
                  v-model="form.orderNo"
                  class="phone-input"
                  placeholder="请输入订单号"
                ></el-input>
              </el-form-item>
              <div class="unsubscribe">
                <el-button
                  @click="unsubscribe"
                  type="primary"
                  class="search-button"
                  >退订场地</el-button
                >
              </div>
            </div>
            <div class="head-wrapper">
              <p class="note-text">消费清单</p>
              <el-form-item prop="customerPhone">
                <el-input
                  style="border-radius: 16px"
                  clearable
                  v-model="form.customerPhone"
                  maxlength="11"
                  class="phone-input"
                  placeholder="请输入会员手机号，并按回车"
                  @keyup.enter.native="getCardList"
                  @input="getPhone"
                ></el-input>
              </el-form-item>
              <el-form-item prop="reservateOrderStatus">
                <el-radio-group
                  v-model="form.reservateOrderStatus"
                  class="sc-type"
                >
                  <el-radio
                    :label="item.value"
                    v-for="(item, index) in DROPDOWNBOX.RESERVATE_ORDER_STATUS"
                    :key="index"
                    >{{ item.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
              <!-- <el-input clearable style="border-radius:16px"  v-model="form.customerPhone" class="phone-input" placeholder="请输入会员手机号" @keyup.enter.native="getCardList"></el-input> -->
            </div>
            <!-- 订单详情 -->
            <div class="ticket-wrap" v-if="orderData.length > 0">
              <div class="venue-price">场地费</div>
              <div class="order-detail-wrap">
                <div class="day-text">
                  {{ currentDay.content }}&nbsp;&nbsp;{{ currentDay.title }}
                  <span>{{ sportsProjectName }}</span>
                </div>
                <div class="venue-wrapper">
                  <div
                    class="venue-order-wrap"
                    v-for="(item, index) in orderData"
                    :key="index"
                  >
                    <div class="name-text">{{ item.spacename }}</div>
                    <div class="content-left">
                      <div
                        class="time-wrap"
                        v-for="(itemX, indexX) in item.spaceData"
                        :key="indexX"
                      >
                        <div class="order-time-text">
                          {{ itemX.timeSlot }}
                          <span class="right"
                            >¥{{ itemX.price }}元/¥{{ itemX.vipPrice }}元</span
                          >
                          <!-- <span class="right" v-else
                            >(会员价)¥{{ itemX.vipPrice }}元</span
                          > -->
                        </div>
                        <div class="camera-fee-wrap" v-if="itemX.hasCamera">
                          <div class="camera-fee-title">
                            <span
                              class="camera-icon el-icon-video-camera"
                            ></span>
                            视频直播服务 &nbsp;&nbsp;
                            <el-checkbox
                              v-model="itemX.liveBroadcast"
                              @change="handleLiveCheckChange"
                            ></el-checkbox>
                            <span class="right"
                              >¥{{ itemX.cameraPrice }}元</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 总计 -->
            <div class="price-wrap">
              <div class="price-site">
                <div class="price-text">
                  <label for="" class="price-name">小计金额</label>
                  <div
                    class="price-modify"
                    v-if="
                      buttonAuth.includes(
                        'venueOperation:order:reservateModifyPrice'
                      )
                    "
                    @click="disabled = !disabled"
                  >
                    修改
                  </div>
                </div>
                <el-input
                  clearable
                  class="radius-input"
                  size="mini"
                  v-if="!disabled"
                  v-model="form.totalPrice"
                  type="number"
                  step="0.01"
                  min="0"
                  :disabled="disabled"
                >
                  <div class="input-slot" slot="suffix">元</div>
                  <div class="input-slot" slot="prefix">¥</div>
                </el-input>
                <div class="price-disabled" v-if="disabled">
                  <span v-if="vipIsChecked">(会员价)</span>¥{{
                    form.totalPrice || 0
                  }}元
                </div>
              </div>
              <!-- <div style="width:100%;display:flex;justify-content:space-between;align-items: center;margin-top:5px;">
              <div>
                <el-switch
                  v-model="isMember"
                  active-text="是否是会员">
                </el-switch>
              </div>
              <div class="price-modify"  v-if="buttonAuth.includes('venueOperation:order:reservateModifyPrice')" @click="disabled = !disabled">修改</div>
            </div> -->
              <!-- </el-form-item> -->
              <!-- <el-button class="change-input" type="primary" round size="mini" v-if="changePay" @click="disabled = false">修改</el-button> -->
              <!-- <el-button class="change-input" type="primary" round size="mini" >修改</el-button> -->
            </div>
            <!--TODO:-->
            <el-form-item label="支付方式:" class="payTypeItem">
              <el-radio-group
                v-model="payTypeRadio"
                class="radioGroup"
                @change="onPayTypeChange"
              >
                <!-- <el-radio label="1">线上-微信</el-radio> -->
                <el-radio label="2">线下-现金</el-radio>
                <el-radio label="5">线下-微信</el-radio>
                <!-- <el-radio label="4">线下-支付宝</el-radio> -->
                <!-- <el-radio label="6">线下-刷卡</el-radio> -->
                <el-radio label="6" v-if="cardList.length > 0"
                  >线下-会员卡</el-radio
                >
              </el-radio-group>
            </el-form-item>
            <!-- <div class="member-card" v-if="payTypeRadio==='6'">
            <el-checkbox v-model="item.checked" v-for="(item,index) in cardList" :key="item.cardId" @change="onCheckMemberCard(index)">{{`${item.cardName} （余额${item.remainAmount}元  ${item.consumeDiscount*1 === 1 ? '不打' : item.consumeDiscount*10}折）`}}</el-checkbox>
          </div> -->
            <el-radio-group
              v-model="payForMember"
              class="member-card"
              v-if="payTypeRadio === '6'"
            >
              <el-checkbox
                v-model="item.checked"
                v-for="(item, index) in cardList"
                :key="item.cardId"
                @change="onCheckMemberCard(index)"
                >{{
                  `${item.cardName} （余额${item.remainAmount}元）`
                }}</el-checkbox
              >
              <!-- ${
                    item.consumeDiscount * 1 === 1
                      ? "不打"
                      : item.consumeDiscount * 10
                  }折 -->
            </el-radio-group>

            <!-- <div class="member-card" v-if="payTypeRadio==='6'">
            <el-radio label="9">通用储值卡</el-radio>
            <el-radio label="8">专用储值卡</el-radio>
          </div> -->
            <!-- 备注 -->
            <div class="remark-wrap">
              <el-input
                clearable
                type="textarea"
                :rows="6"
                maxlength="100"
                placeholder="请输入备注"
                v-model="form.businessRemark"
              >
              </el-input>
              <!--TODO:-->
              <!-- <div class="pay-money">待支付 <span class="money">¥ {{form.totalPrice}} 元</span></div> -->
              <div class="pay-money">
                待支付 <span class="money">¥ {{ finalPrice }} 元</span>
              </div>
            </div>
            <!-- 按钮 -->
            <div class="button-wrap">
              <div
                class="default-button btn-save"
                v-if="buttonAuth.includes('venueOperation:order:reservateSave')"
                @click="settlementOrder(1)"
              >
                保存
              </div>
                <div
                  class="default-button"
                  v-if="
                    buttonAuth.includes('venueOperation:order:reservateSave')
                  "
                  @click="settlementOrder(2)"
                >
                  结算
                </div>
            </div>
          </div>
        </el-form>
      </div>
      <!-- 结算弹窗 -->
      <add-dialog
        ref="addDialog"
        :Language="Language"
        typeText="预约"
        :show="addDialog.visible"
        :isTicket="addDialog.isTicket"
        @closeDialog="changeAddDialog"
        @refesh="getData2(dayList[choseDay], choseDay)"
        @checkDetail="checkDetail"
      ></add-dialog>
      <confirm-dialog
        ref="confirmDialog"
        :Language="Language"
        :show="confirmDialog.visible"
        :type="confirmDialog.type"
        @sureDialog="sureConfirmDialog"
        @closeDialog="changeConfirmDialog"
      ></confirm-dialog>
      <!-- 小票弹窗 -->
      <DetailDialog
        ref="detailDialog"
        :show="detailDialog.visible"
        @closeDialog="changeDetailDialog"
      ></DetailDialog>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import mixin from "@/mixins/index";
import apis from "@/apis";
import addDialog from "./addDialog.vue";
import OrderClass from "./orderClass";
import confirmDialog from "@/components/confirmDialog.vue";
import DetailDialog from "./detailDialog.vue";
import { fomatFloat } from "@/utils/index.js";
import { debounce } from "lodash";

export default {
  mixins: [mixin],
  data() {
    return {
      isMember: false,
      payTypeRadio: "", // 支付方式
      payForMember: "7",
      sportRadio: "", // 运动项目选中值
      sportsProjectCode: "",
      sportsProjectName: "",
      firstSportList: [],
      vipIsChecked: "",
      allSportList: [
        {
          sportsProjectName: "",
          sportsProjectCode: "",
        },
      ],
      radio: 1,
      sportList: [],
      checkList: {
        sportsProjectCode: "",
        sportsProjectName: "",
      },
      formTop: {
        sportsProjectCode: "",
        sportsProjectName: "",
      },
      cardSlct: false,
      disabled: true,
      weekNameList: [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ],
      choseDay: 0,
      currentDay: {},
      tableData: [],
      tableHeaderData: [],
      orderData: [],
      textarea: "",
      form: new OrderClass("form"),
      rules: new OrderClass("rule"),
      addDialog: {
        visible: false,
        isTicket: false,
      },
      confirmDialog: {
        visible: false,
        type: 6,
        data: {},
      },
      detailDialog: {
        visible: false,
      },
      row: null,
      info: null,
      data: null,
      index: null,
      cardList: [],
      discount: 1,
    };
  },
  computed: {
    finalPrice() {
      // return (this.form.totalPrice * this.discount * 1).toFixed(2) // 保留两位小数
      return fomatFloat(this.form.totalPrice * this.discount * 1, 2); // 四舍五入并保留两位小数
    },
    dayList() {
      const arr = [];
      const time = new Date().getTime();
      for (let i = 0; i < 14; i += 1) {
        arr.push({
          title:
            i === 0
              ? "今日"
              : this.weekNameList[new Date(time + 3600 * 24000 * i).getDay()],
          content: this.updateTimeFormat(time + 3600 * 24000 * i),
          weekDay:
            new Date(time + 3600 * 24000 * i).getDay() === 0
              ? 7
              : new Date(time + 3600 * 24000 * i).getDay(),
        });
      }
      return arr;
    },
    ...mapState(["Language", "changePay", "venueId", "TOKEN"]),
  },
  components: {
    addDialog,
    confirmDialog,
    DetailDialog,
  },
  methods: {
    /* eslint-disable */
    renderHeader(h, { column, $index }) {
      return [
        h("span", {
          class: {
            "el-icon-video-camera":
              this.tableHeaderData[$index - 1] &&
              this.tableHeaderData[$index - 1].hasCamera
                ? true
                : false,
            "video-camera":
              this.tableHeaderData[$index - 1] &&
              this.tableHeaderData[$index - 1].hasCamera
                ? true
                : false,
          },
        }),
        column.label,
      ];
    },
    // 视频勾选回调
    handleLiveCheckChange() {
      // 重新计算
      this.calcTotalPriceAndSort();
    },
    async getSportsProjectListById(cb) {
      await this.$http
        .get(`${apis.getSportsNewProject}?id=${this.venueId}`)
        .then((res) => {
          console.log("获取运动项目的信息", res);
          if (res.data.code === 0) {
            if (res.data && res.data.rows && res.data.rows.length > 0) {
              this.sportRadio = res.data.rows[0].sportsProjectCode;
              this.sportsProjectCode = res.data.rows[0].sportsProjectCode;
              this.sportsProjectName = res.data.rows[0].sportsProjectName;
              this.sportList = res.data.rows;
              if (cb) cb();
            } else {
              this.sportRadio = "";
              this.sportList = [];
            }
          }
        });
    },
    unsubscribe() {
      const params = {
        orderNo: this.form.orderNo,
        venueId: this.venueId,
      };
      this.$http
        .get(apis.cancelReserve, {
          params,
        })
        .then((res) => {
          if (res.data.code === 0) {
            window.v.$message.success("0元退场完成!");
            this.getData1();
          }
        });
    },
    //监听手机号
    getPhone() {
      // console.log(value);
      // if (value == "" || value.length < 11) {
      //   this.cardList = [];
      //   this.payTypeRadio = "";
      // }
      this.cardList = [];
      this.payTypeRadio = "";
    },
    getCardList() {
      const params = {
        tel: this.form.customerPhone,
        venueId: this.venueId,
      };
      this.cardList = [];
      if (!params.tel) return;
      this.$http
        .get(apis.getCardListByTel, {
          params,
        })
        .then((res) => {
          if (res.data.code === 0) {
            const data = res.data.data;
            if (data && data.length > 0) {
              console.log(data);
              const list = data.map((item) => ({
                cardId: item.cardId,
                cardName: item.cardName,
                remainAmount: item.remainAmount,
                consumeDiscount: item.consumeDiscount,
                checked: false,
              }));
              console.log(list);
              this.cardList = list;
              console.log(this.cardList);
            }
          }
        });
      // console.log(this.cardList.length);
    },
    // 支付方式改变
    onPayTypeChange(value) {
      console.log(value);
      if (value !== "3") {
        // 清空储值卡
        this.cleanMemberCardList();
        this.discount = 1;
      }
      if (value !== "6") {
        // this.getCardList()
        // console.log(this.cardList);
        this.calcTotalPriceAndSort();
      }
    },
    // 清空储值卡
    cleanMemberCardList() {
      if (this.cardList.length > 0) {
        const list = this.cardList.map((item) => ({
          cardId: item.cardId,
          cardName: item.cardName,
          remainAmount: item.remainAmount,
          consumeDiscount: item.consumeDiscount,
          checked: false,
        }));
        this.cardList = list;
      }
    },
    onCheckMemberCard(num) {
      console.log(num);
      this.calcTotalPriceAndSort();
      this.cardList.map((item, index) => {
        console.log(item);
        if (index !== num) {
          item.checked = false;
        }
        return item;
      });
      //重算
      this.calcDiscount();
    },
    calcDiscount() {
      const index = this.cardList.findIndex((item) => item.checked);
      if (index > -1) {
        this.discount = this.cardList[index].consumeDiscount * 1;
      } else {
        this.discount = 1;
      }
    },
    onChangeCode(item) {
      if (item) {
        this.sportsProjectCode = item;
        this.sportList.forEach((i) => {
          if (i.sportsProjectCode === item) {
            this.sportsProjectName = i.sportsProjectName;
          }
        });
        this.getData1();
      } else {
        console.log("取消");
      }
    },
    changeConfirmDialog(dialogStatus, type) {
      this.confirmDialog.type = type;
      this.confirmDialog.visible = dialogStatus;
    },
    sureConfirmDialog() {
      if (this.confirmDialog.type === 6) {
        this.confirmDialog.visible = false;
        this.formatData(this.row, this.info, false);
        this.calcTotalPriceAndSort();
        this.row.reservateStatus = "1";
      } else if (this.confirmDialog.type === 7) {
        this.confirmDialog.visible = false;
        this.orderData = [];
        this.form.totalPrice = 0;
        let info = {};
        if (!this.data) {
          info = {
            date: this.dayList[0].content,
            // venueId: 2,
            week: this.dayList[0].weekDay,
            sportsProjectCode: this.sportsProjectCode,
          };
        } else {
          this.choseDay = this.index;
          info = {
            date: this.data.content,
            week: this.data.weekDay,
            sportsProjectCode: this.sportsProjectCode,
          };
        }
        this.$http.post(apis.reservateOrder_list, info).then((res) => {
          if (res.data.code === 0) {
            this.tableData = res.data.rows.timeSlotDetailList.filter(
              (item) => item.isVisible === "1"
            );
            // this.tableHeaderData = res.data.rows.spaceList
            this.tableHeaderData = res.data.rows.spaceAndCameraList;
          }
        });
      }
    },
    changeSelect(row, info, item) {
      console.log(row);
      console.log(info);
      console.log(item);
      if (row.reservateStatus === "1") {
        row.reservateStatus = "4";
        this.formatData(row, info, true, item);
        this.calcTotalPriceAndSort();
      } else if (row.reservateStatus === "4") {
        this.changeConfirmDialog(true, 6, row, info);
        this.row = row;
        this.info = info;
        console.log("row", row, info);
        // this.$confirm('当前勾选信息会被清空，您确定要更改预约日期?', '系统提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        //   type: 'warning',
        // }).then(() => {
        //   this.formatData(row, info, false)
        //   this.calcTotalPriceAndSort()
        //   row.reservateStatus = '1'
        // }).catch(() => {
        //   console.log(222)
        // })
      } else if (row.reservateStatus === "2") {
        // 已付费
        // 点击小票清单
        const orderId = (row && row.orderId) || "";
        this.checkDetail(orderId);
      }
    },
    calcTotalPriceAndSort() {
      this.form.totalPrice = 0;
      this.orderData.sort((a, b) => {
        return Number(a.spaceId) - Number(b.spaceId);
      });
      this.orderData.forEach((item) => {
        if (item.spaceData.length > 0) {
          item.spaceData.sort((a, b) => {
            return a.timeSort - b.timeSort;
          });
          item.spaceData.forEach((itemX) => {
            // 有视频直播且勾选
            if (itemX.hasCamera && itemX.liveBroadcast) {
              this.form.totalPrice +=
                Number(itemX.price) + Number(itemX.cameraPrice);
            } else {
              if (this.cardList.length === 0) {
                this.form.totalPrice += Number(itemX.price);
              } else {
                let isChecked = false
                this.cardList.forEach((item) => {
                  // 根据储值卡的选中情况来判断是否是会员价格
                  if (item.checked) {
                    isChecked = true
                  }
                });
                this.vipIsChecked = isChecked;
                if (isChecked) {
                  this.form.totalPrice += Number(itemX.vipPrice)
                } else {
                  this.form.totalPrice += Number(itemX.price)
                }
              }
            }
          });
        }
      });
      this.form.totalPrice = this.form.totalPrice.toFixed(2);
      console.log(this.form.totalPrice);
    },
    formatData(data, info, type, cloumnItem) {
      if (type) {
        if (this.orderData.length > 0) {
          const index1 = this.orderData.findIndex((item) => {
            return item.spaceId === data.spaceId;
          });
          if (index1 > -1) {
            this.orderData[index1].spaceData.push({
              ...data,
              ...{
                timeSort: info.timeSort,
                qty: 1,
                sportsProjectCode: this.sportsProjectCode,
                sportsProjectName: this.sportsProjectName,
                hasCamera: cloumnItem.hasCamera,
                liveBroadcast: cloumnItem.liveBroadcast,
                cameraPrice: cloumnItem.cameraPrice,
              },
            });
          } else {
            const obj = {};
            obj.spacename = data.spaceName;
            obj.spaceId = data.spaceId;
            obj.spaceData = [];
            obj.spaceData.push({
              ...data,
              ...{
                timeSort: info.timeSort,
                qty: 1,
                sportsProjectCode: this.sportsProjectCode,
                sportsProjectName: this.sportsProjectName,
                hasCamera: cloumnItem.hasCamera,
                liveBroadcast: cloumnItem.liveBroadcast,
                cameraPrice: cloumnItem.cameraPrice,
              },
            });
            this.orderData.push(obj);
          }
        } else {
          const obj = {};
          obj.spacename = data.spaceName;
          obj.spaceId = data.spaceId;
          obj.spaceData = [];
          obj.spaceData.push({
            ...data,
            ...{
              timeSort: info.timeSort,
              qty: 1,
              sportsProjectCode: this.sportsProjectCode,
              sportsProjectName: this.sportsProjectName,
              hasCamera: cloumnItem.hasCamera,
              liveBroadcast: cloumnItem.liveBroadcast,
              cameraPrice: cloumnItem.cameraPrice,
            },
          });
          this.orderData.push(obj);
        }
      } else {
        // 删除
        const spaceIndex = this.orderData.findIndex((item) => {
          return item.spaceId === data.spaceId;
        });
        const spaceChildIndex = this.orderData[spaceIndex].spaceData.findIndex(
          (item) => {
            return item.timeSlot === data.timeSlot;
          }
        );
        this.orderData[spaceIndex].spaceData.splice(spaceChildIndex, 1);
        this.orderData.forEach((item, index) => {
          if (!item.spaceData.length) {
            this.orderData.splice(index, 1);
          }
        });
      }
    },
    getData(data, index = 0) {
      this.currentDay = {
        content: this.dayList[index].content,
        title: this.dayList[index].title,
        week: this.dayList[index].weekDay,
        date: this.dayList[index].content,
      };
      if (this.orderData.length !== 0) {
        this.changeConfirmDialog(true, 7);
        this.data = data;
        this.index = index;
        // this.$confirm(this.$t('close-confirm'), this.$t('tips'), {
        //   confirmButtonText: this.$t('sure'),
        //   cancelButtonText: this.$t('cancel'),
        //   type: 'warning',
        // }).then(() => {
        //   this.orderData = []
        //   this.form.totalPrice = 0
        //   let info = {}
        //   if (!data) {
        //     info = {
        //       date: this.dayList[0].content,
        //       // venueId: 2,
        //       week: this.dayList[0].weekDay,
        //     }
        //   } else {
        //     this.choseDay = index
        //     info = {
        //       date: data.content,
        //       week: data.weekDay,
        //     }
        //   }
        //   this.$http.post(apis.reservateOrder_list, info).then((res) => {
        //     if (res.data.code === 0) {
        //       this.tableData = res.data.rows.timeSlotDetailList
        //       this.tableHeaderData = res.data.rows.spaceList
        //     }
        //   })
        // }).catch(() => {
        // })
      } else {
        this.choseDay = index;
        const info = {
          date: data.content,
          week: data.weekDay,
          sportsProjectCode: this.sportsProjectCode,
        };
        this.$http.post(apis.reservateOrder_list, info).then((res) => {
          if (res.data.code === 0) {
            this.tableData = res.data.rows.timeSlotDetailList.filter(
              (item) => item.isVisible === "1"
            );
            this.tableHeaderData = res.data.rows.spaceAndCameraList;
          }
        });
      }
    },
    getData2(data, index) {
      this.form.customerPhone = ''
      this.getData1(data, index)
    },
    getData1(data, index = 0) {
      this.choseDay = index;
      this.orderData = [];
      this.form.totalPrice = 0;
      this.calcTotalPriceAndSort();
      this.cardList = []
      this.payTypeRadio = ''
      this.form.businessRemark = ''
      let info = {};
      if (!data) {
        info = {
          date: this.dayList[0].content,
          // venueId: 2,
          week: this.dayList[0].weekDay,
          sportsProjectCode: this.sportsProjectCode,
        };
        this.currentDay = {
          content: this.dayList[0].content,
          title: this.dayList[0].title,
          week: this.dayList[0].weekDay,
          date: this.dayList[0].content,
        };
      } else {
        this.currentDay = {
          content: this.dayList[index].content,
          title: this.dayList[index].title,
          week: this.dayList[index].weekDay,
          date: this.dayList[index].content,
        };
        info = {
          date: data.content,
          week: data.weekDay,
          sportsProjectCode: this.sportsProjectCode,
        };
      }
      console.log("info====获取参数", info);
      // 折扣清除
      this.discount = 1;
      // TODO:
      this.$http.post(apis.reservateOrder_list, info).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows.timeSlotDetailList.filter(
            (item) => item.isVisible === "1"
          );
          // this.tableHeaderData = res.data.rows.spaceList
          this.tableHeaderData = res.data.rows.spaceAndCameraList;
          // 查询储值卡片信息
          this.$nextTick(() => {
            this.getCardList(); // 获取卡片信息
          });
        }
      });
    },
    changeAddDialog() {
      this.addDialog.visible = false;
      this.getData1(this.dayList[this.choseDay], this.choseDay);
    },
    settlementOrder: debounce(function (type) {
      const commitData = {
        businessRemark: this.form.businessRemark,
        customerPhone: this.form.customerPhone,
        reservateOrderStatus: this.form.reservateOrderStatus,
        detailSaveInputList: [],
        totalPrice: this.form.totalPrice,
        week: this.currentDay.week,
        date: this.currentDay.date,
      };
      this.orderData.forEach((item) => {
        if (item.spaceData.length) {
          item.spaceData.forEach((itemX) => {
            commitData.detailSaveInputList.push(itemX);
          });
        }
      });
      console.log(commitData.totalPrice);
      if(this.payTypeRadio == ''){
        this.$message.error("请选择支付方式");
        return;
      }
      if (!commitData.detailSaveInputList.length) {
        this.$message.error("请选择场地日期");
        return;
      }
      var re = /^1\d{10}$/;
      if (!re.test(commitData.customerPhone)) {
        this.$message.error("请输入手机号");
        return;
      }
      // 通过卡种判断
      let memberCardId = "";
      console.log(this.cardList);
      const index = this.cardList.findIndex((item) => item.checked);
      if (this.payTypeRadio === "6") {
        // 是否选择储值卡
        if (index > -1) {
          memberCardId = this.cardList[index].cardId;
          console.log(this.cardList[index]);
        } else {
          this.$message.info("请先选择会员卡!");
          return;
        }
      }
      console.log(memberCardId);
      commitData.payType = this.payTypeRadio;
      commitData.memberCardId = memberCardId;
      commitData.finalPrice = this.finalPrice;
      console.log(commitData, "commitData");
      this.$refs.addDialog.getDataQuery(commitData, type);
      if(this.payTypeRadio === '5'){
        if (type === 2) {
          this.addDialog.visible = true;
        }
      }
      
    }, 500),
    searchData() {},
    // 页面初始化
    getDefaultInfo() {
      this.getSportsProjectListById(this.getData1);
    },
    /**
     * @function 打开小票预览弹窗
     * @param {Array} id 选中的ID数组
     */
    checkDetail(id) {
      this.detailDialog.visible = true;
      this.$refs.detailDialog.getDataQuery(id);
    },
    /**
     * @function 关闭小票预览弹窗
     */
    changeDetailDialog() {
      this.detailDialog.visible = false;
    },
  },
  activated() {
    this.getDefaultInfo();
  },
};
</script>

<style lang="less" scoped>
@import "../../../../assets/styles/mixin.scoped.less";
.unsubscribe {
  display: flex;
  justify-content: center;
}
.order-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .main-container {
    width: calc(100% - 400px);
    height: calc(100vh - 174px);
    display: flex;
    flex-direction: column;
    .order-table {
      overflow: scroll;
    }
  }
  .order-container {
    width: 400px;
    height: 100%;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    border: 1px solid rgba(228, 233, 241, 1);
    margin-right: 20px;
    overflow: scroll;
  }
}
.tabs-container {
  margin: 0 20px;
}
.bto {
  margin-top: 50px;
  width: 300px;
  .el-button {
    width: 23%;
    min-width: 50px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    font-size: 12px;
  }
}

.wrap {
  // height: calc(100vh - 200px);
  // background: gray;
}

.defauClass {
  background: 1px solid #b0b0b0;
  // display: inline-flex;
  // height: 100%;
}

.dangerClass {
  background: #ff861e;
  color: #fff;
  margin: 0;
}
.coachClass {
  background: red;
  color: #fff;
  margin: 0;
}

.succeClass {
  background: #439bff;
  color: #fff;
  margin: 0;
}

.infoClass {
  background: #b0b0b0;
  margin: 0;
}

.main-container {
  padding: 0;
  .header-container {
    // width: 100%;
    padding-left: 30px;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    border: 1px solid rgba(228, 233, 241, 1);
    // display: flex;
    // justify-content: space-between;
    .day-container {
      width: 69px;
      height: 83px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      // margin-top: 30px;
      margin-bottom: 15px;
      overflow: hidden;
      float: left;
      margin-right: 10px;
      cursor: pointer;

      .title {
        // width: 100%;
        height: 29px;
        background-color: #bdbbbb;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 29px;
        text-align: center;
        display: block;
      }

      .content {
        // width: 100%;
        height: 54px;
        display: block;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(82, 82, 82, 1);
        line-height: 54px;
        text-align: center;
      }

      &.chose-day {
        .title {
          background-color: #439bff;
        }
      }
    }
    .sports {
      flex: 1;
      // width: 100%;
      // height: 83px;
      margin: 10px 50px 0 0;
      // overflow: hidden;
    }
    .radio {
      margin-bottom: 5px;
    }
  }
  .table-container-wrapper {
    // width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(228, 233, 241, 1);
    .table-order-tip {
      text-align: right;
      margin-bottom: 12px;
      .tip-wrapper {
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
        color: rgba(82, 82, 82, 1);
        margin-right: 12px;
      }
      .tip {
        width: 6px;
        height: 6px;
        margin-right: 5px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 4px;
      }
      .tip-optional {
        border: 1px solid rgba(176, 176, 176, 1);
      }
      .tip-selected {
        background: rgba(67, 155, 255, 1);
      }
      .tip-schedule {
        background: rgba(255, 134, 30, 1);
      }
      .tip-coach {
        background: red;
      }
      .tip-locked {
        background: rgba(176, 176, 176, 1);
      }
    }
  }
  // background: red;
}

.order-container {
  // background: blue;
  margin-left: 20px;
  .note-container {
    // width: 300px;
    max-height: calc(100vh - 270px);
    overflow-y: auto;
    // padding-bottom: 200px;
    position: relative;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    // border:1px solid rgba(228,233,241,1);
    .coach-wrapper {
      padding-bottom: 30px;
      border-bottom: 2px solid #e4e9f1;
    }
    .head-wrapper {
      padding-bottom: 20px;
      border-bottom: 1px solid #e4e9f1;
    }
    .note-text {
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(36, 45, 65, 1);
      text-align: center;
      margin-top: 20px;
    }
    .phone-input {
      width: calc(100% - 30px);
      margin: 0 15px;
      margin-top: 20px;
      border-radius: 16px;
    }
    .sc-type {
      margin: 10px auto 0 auto;
      text-align: center;
      width: 100%;
    }
    .ticket-wrap {
      margin-top: 10px;
      padding: 0 15px;
      .venue-price {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(36, 45, 65, 1);
        margin-bottom: 15px;
      }
      .order-detail-wrap {
        .day-text {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(36, 45, 65, 1);
          height: 40px;
          // text-indent: 15px;
          line-height: 40px;
        }
        .venue-wrapper {
          display: flex;
          flex-direction: column;
          max-height: 170px;
          overflow: auto;
        }
        .venue-order-wrap {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          .name-text {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(148, 152, 158, 1);
            // text-indent: 15px;
            line-height: 17px;
            width: 60px;
          }
          .content-left {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding-left: 10px;
          }
          .time-wrap {
            .order-time-text {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(148, 152, 158, 1);
              // text-indent: 15px;
              line-height: 17px;
              display: flex;
              justify-content: space-evenly;
              .right {
                margin-left: 60px;
              }
            }
            .camera-fee-wrap {
              display: flex;
              .camera-fee-title {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: rgba(148, 152, 158, 1);
                .camera-icon {
                  font-size: 14px;
                  margin-right: 3px;
                }
                .right {
                  margin-left: 14px;
                }
              }
              margin-bottom: 6px;
            }
          }
        }
      }
    }
    .price-wrap {
      // width: 100%;
      // display: flex;
      padding: 18px 10px 8px;
      border-bottom: 1px solid #e4e9f1;
      // justify-content: space-between;
      // align-items: center;
      .price-site {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .price-text {
          display: flex;
          flex-direction: row;
        }
        .price-name {
          width: 74px;
          line-height: 28px;
          font-size: 14px;
          color: rgba(36, 45, 65, 1);
        }
        .price-modify {
          width: 40px;
          padding-top: 10px;
        }
        .price-disabled {
          // width: calc(100% - 138px);
          margin-right: 20px;
          font-size: 12px;
          color: rgba(36, 45, 65, 1);
        }
        .radius-input {
          width: calc(100% - 200px);
          margin-right: 20px;
          .input-slot {
            line-height: 28px;
            height: 28px;
            font-size: 12px;
            color: #242d41;
          }
        }
        // .el-form-item{
        //   float: left;
        //   width: 78%;
        // }
        // .change-input{
        //   float: left;
        //   width: 19%;
        //   margin-left: 8px;
        // }
      }
    }
    .member-card {
      padding: 8px 10px;
      display: flex;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      .el-radio {
        margin: 0 !important;
      }
    }
    .payTypeItem {
      // background-color: #D2514B;
      padding: 10px 0 0 10px;
      margin-bottom: 0;
      .el-form-item__label {
        line-height: 0 !important;
      }
    }
    .radioGroup {
      display: flex;
      flex-direction: column;
      padding: 0 16px;
      label {
        padding: 3px 0;
      }
    }
    .remark-wrap {
      // border-bottom: 1px solid #E4E9F1;
      .pay-money {
        font-size: 12px;
        font-weight: 400;
        color: rgba(36, 45, 65, 1);
        text-align: right;
        margin-top: 10px;
        .money {
          margin-left: 10px;
          color: rgba(210, 81, 75, 1);
        }
      }
      padding: 20px 10px;
      p {
        text-align: right;
        font-size: 12px;
        line-height: 30px;
        height: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(36, 45, 65, 1);
        span {
          color: #d2514b;
        }
      }
    }
    .button-wrap {
      // width: 100%;
      margin-top: 20px;
      padding: 0 10px;
      display: grid;
      grid-template-columns: 50% 50%;
      align-items: center;
      justify-items: center;
      justify-content: center;
      align-content: center;
      .default-button {
        width: 122px;
        height: 32px;
        background: #eaecf1;
        border-radius: 4px;
        line-height: 32px;
        height: 32px;
        border: 0;
        text-align: center;
        margin: 0 auto;
        color: #439bff;
        cursor: pointer;
        &:hover {
          color: #ffffff;
          background: #439bff;
        }
      }
      .btn-save {
        background: #439bff;
        color: #fff;
      }
    }
  }
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
.price-modify {
  font-size: 10px;
  font-weight: 400;
  color: rgba(210, 81, 75, 1);
  cursor: pointer;
  // text-align: right;
  padding-right: 20px;
}
.change-input {
  margin-top: 6px;
}
.order-table {
  .el-table th,
  .el-table td {
    padding: 1px 0 !important;
  }
  .el-table .cell {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .el-table {
    .video-camera {
      font-size: 16px;
      margin-right: 3px;
    }
  }
  .el-table__header th {
    background: none !important;
    border-color: #fff;
    border-bottom: 1px solid #ebeef5;
    height: 40px;
  }
  // .el-table__body-wrapper {
  //   height: 100% !important;
  // }
  .el-table__empty-block {
    width: 100% !important;
  }
  .table-no-data {
    .el-table__header th {
      border-bottom: 0 !important;
    }
  }
}
.head-wrapper {
  .el-input__inner {
    border-radius: 19px !important;
  }
}
.venue-radio-wrapper {
  .el-radio-button__inner {
    margin-right: 10px !important;
    background: rgba(238, 240, 244, 1) !important;
    border-radius: 4px !important;
    color: rgba(172, 174, 176, 1) !important;
    font-size: 14px !important;
    border: 1px solid rgba(238, 240, 244, 1) !important;
  }
}
.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  border-radius: 4px !important;
  border: 1px solid rgba(68, 155, 255, 1) !important;
  font-size: 14px !important;
  color: rgba(68, 155, 255, 1) !important;
  background: rgba(68, 155, 255, 0.3) !important;
}
// .el-radio-button:first-child .el-radio-button__inner{
//   border-radius:4px !important;
//   border:1px solid rgba(68,155,255,1) !important;
//   font-size:14px !important;
//   color:rgba(68,155,255,1) !important;
//   background:rgba(68,155,255,0.3) !important;
// }
.checked {
  border-radius: 4px !important;
  border: 1px solid rgba(68, 155, 255, 1) !important;
  font-size: 14px !important;
  color: rgba(68, 155, 255, 1) !important;
  background: rgba(68, 155, 255, 0.3) !important;
}
</style>
